.bots {
  margin-top: 20px;
}
.title {
  margin:0 20px;
  font-weight: 800;
  font-size: 14px;
  color: #999;
  text-transform: uppercase;
}
.bot {
  border: 1px solid rgb(200, 200, 200);
  padding:0 20px;
}
.bot.active {
  background-color: rgb(225, 225, 225);
}